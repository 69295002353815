import ChevronRightSVG from '@/icons/ChevronRightSVG';

import type { HomeCollectionList } from '@/api/types/home.types';
import { Movie } from '@/api/types/movie.types';

import { ForwardedLink } from '@/components/ForwardedLink';
import { MovieCard } from '@/components/MovieCard';
import { MovieListViewAllButton } from '@/components/MovieCard/MovieListViewAllButton';

import styles from '@/views/HomeView/HomeCollections/HomeCollectionsRow.module.scss';
import { HomeContentBaseSlider } from '@/views/HomeView/HomeContentBaseSlider';

interface HomeCollectionsRowProps extends Pick<HomeCollectionList, 'id' | 'title'> {
  movies: Movie[];
}

export const HomeCollectionsRow = (props: HomeCollectionsRowProps) => {
  const { movies, title, id } = props;

  return (
    <HomeContentBaseSlider
      heading={
        <ForwardedLink href={`/collection/11/${id}`} className={styles.sectionHeading}>
          {title}
          <ChevronRightSVG className={styles.icon} />
        </ForwardedLink>
      }
      className={styles.homeCollectionsSlider}
      slideClassName={styles.collectionsSlide}
    >
      {movies.map(movie => (
        <MovieCard key={movie.id} movie={movie} />
      ))}
      <MovieListViewAllButton href={`/collection/11/${id}`} />
    </HomeContentBaseSlider>
  );
};
