/* eslint-disable @next/next/no-img-element */
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { Card, CardBody, CardTitle } from '@/components/Card';
import styles from '@/components/MovieCard/MovieCardSkelton.module.scss';

// =================================================================

const CARD_ASPECT_RATIO = 181 / 272;

// =================================================================

export const MovieCardSkelton = () => {
  return (
    <Card className={styles.movieCard}>
      <div style={{ paddingBottom: `${100 / CARD_ASPECT_RATIO}%` }} className={styles.skeltonImage}>
        <img src="/assets/images/movie-card-placeholder.png" alt="movie-card-skelton" />
      </div>
      <CardBody className={styles.movieCardBody}>
        <CardTitle>
          <SkeletonTheme baseColor="#0F0F12" highlightColor="#47474A" width={120}>
            <Skeleton />
          </SkeletonTheme>
        </CardTitle>
      </CardBody>
    </Card>
  );
};
