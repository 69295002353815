import { forwardRef } from 'react';

import clsx from 'clsx';

import ArrowLeftSVG from '@/icons/PaginationArrowLeftSVG';
import ArrowRightSVG from '@/icons/PaginationArrowRightSVG';

import styles from '@/components/Slider/SlideNavigationButton.module.scss';

// =================================================================

interface SlideNavigationButtonProps {
  iconPosition: 'left' | 'right';
  isVisible: boolean;
}

// =================================================================

export const SlideNavigationButton = forwardRef<HTMLButtonElement, SlideNavigationButtonProps>(
  (props, ref) => {
    const { iconPosition, isVisible } = props;

    return (
      <button
        ref={ref}
        className={clsx(styles.navigationButton, styles[iconPosition], isVisible && styles.visible)}
      >
        <div className={styles.iconWrapper}>
          <span className={styles.icon}>
            {iconPosition === 'left' ? <ArrowLeftSVG /> : <ArrowRightSVG />}
          </span>
        </div>
      </button>
    );
  },
);
