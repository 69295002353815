import { FadeTransition } from '../Animations';
import clsx from 'clsx';

import type { ChildrenProps } from '@/types/common.types';

import styles from '@/components/Slider/SliderNavigations.module.scss';

// =================================================================

export const SliderNavigations = (props: ChildrenProps) => {
  const { children } = props;

  return <div className={clsx(styles.sliderNavigations)}>{children}</div>;
};
