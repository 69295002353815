import { type ReactNode } from 'react';

import { bannerLinkTypes, breakpoints } from '@/app.config';

import type { Banner } from '@/api/types/home.types';

import { ForwardedLink } from '@/components/ForwardedLink';

import styles from '@/views/HomeView/BannerSlider/BannerSlide.module.scss';

// =================================================================

interface BannerSlideProps {
  children?: ReactNode;
  desktopBanner: string;
  mobileBanner: string;
  banner: Banner;
}

// =================================================================

export const BannerSlide = (props: BannerSlideProps) => {
  const { children, desktopBanner, mobileBanner } = props;

  const { linkType, link, moduleId } = props.banner;

  const isLinkTypeOfCollection = linkType === bannerLinkTypes.collection;
  const isLinkTypeOfExternal = linkType === bannerLinkTypes.externalLink;

  let url: string;
  if (isLinkTypeOfExternal) {
    url = link;
  } else if (isLinkTypeOfCollection) {
    url = '';
  } else {
    url = `/watch/${moduleId}/${link}`;
  }

  return (
    <ForwardedLink href={url} className={styles.bannerSlide}>
      <picture className={styles.imageContainer}>
        <source
          // srcSet="/assets/images/banner-img-1.png"
          srcSet={desktopBanner}
          media={breakpoints.small}
          className={styles.bannerImage}
        />
        <source
          // srcSet="/assets/images/banner-mobile-img-1.png"
          srcSet={mobileBanner}
          media={`${breakpoints.extraSmall} and (orientation: portrait)`}
          className={styles.bannerImage}
        />
        <source
          // srcSet="/assets/images/banner-mobile-img-1.png"
          srcSet={mobileBanner}
          media={`${breakpoints.extraSmall} and (orientation: landscape)`}
          className={styles.bannerImage}
        />
        <img
          // src="/assets/images/banner-img-1.png"
          srcSet={desktopBanner}
          alt="default-banner"
          className={styles.bannerImage}
        />
      </picture>
      {children}
    </ForwardedLink>
  );
};
