'use client';

import React, { useRef, ReactNode } from 'react';

import clsx from 'classnames';

import { ChildrenProps, ClassNameProps } from '@/types/common.types';

import { useHover } from '@/hooks/useHover';

import { SectionHeading } from '@/components/SectionHeading';
import { SlideNavigationButton } from '@/components/Slider/SlideNavigationButton';
import styles from '@/components/Slider/Slider.module.scss';
import { SliderNavigations } from '@/components/Slider/SliderNavigations';
import { Swiper, SwiperSlide } from '@/components/Slider/Swiper';

// =================================================================

export interface SliderProps extends ClassNameProps, ChildrenProps {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
  xxl: number;
  heading: ReactNode;
  slideClassName?: string;
  gaps?: typeof defaultGaps;
}

// =================================================================

const defaultGaps = {
  xs: 12,
  sm: 12,
  md: 12,
  lg: 18,
  xl: 20,
  xxl: 20,
};

// =================================================================

export const Slider = (props: SliderProps) => {
  const {
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
    heading,
    className,
    gaps = defaultGaps,
    children,
    slideClassName,
  } = props;

  const previousSlideButtonRef = useRef<HTMLButtonElement | null>(null);
  const nextSlideButtonRef = useRef<HTMLButtonElement | null>(null);

  const { isHovered, ref: sliderWrapperRef } = useHover<HTMLDivElement>();

  return (
    <div className={clsx(styles.slider, className)}>
      <div className={styles.topPanel}>
        <SectionHeading variant="gradient">{heading}</SectionHeading>
      </div>
      <div ref={sliderWrapperRef} className={styles.swiperWrapper}>
        <Swiper
          wrapperTag="ul"
          className={styles.swiperContainer}
          navigation={{
            prevEl: previousSlideButtonRef.current,
            nextEl: nextSlideButtonRef.current,
          }}
          virtual={false}
          mousewheel={{ forceToAxis: true, thresholdDelta: 25, thresholdTime: 50 }}
          onBeforeInit={swiper => {
            if (swiper.params.navigation && typeof swiper.params.navigation !== 'boolean') {
              if (previousSlideButtonRef.current && nextSlideButtonRef.current) {
                swiper.params.navigation.prevEl = previousSlideButtonRef.current;
                swiper.params.navigation.nextEl = nextSlideButtonRef.current;
              }
            }
          }}
          breakpoints={{
            0: {
              slidesPerView: xs,
              slidesPerGroup: xs,
              spaceBetween: gaps.xs,
            },
            576: {
              slidesPerView: sm,
              slidesPerGroup: sm - 1,
              spaceBetween: gaps.sm,
            },
            768: {
              slidesPerView: md,
              slidesPerGroup: md - 1,
              spaceBetween: gaps.md,
            },
            992: {
              slidesPerView: lg,
              slidesPerGroup: lg - 2,
              spaceBetween: gaps.lg,
            },
            1200: {
              slidesPerView: xl,
              slidesPerGroup: xl - 2,
              spaceBetween: gaps.xl,
            },
            1400: {
              slidesPerView: xxl,
              slidesPerGroup: xxl - 2,
              spaceBetween: gaps.xxl,
            },
          }}
        >
          {React.Children.map(children, (child, index) => (
            <SwiperSlide tag="li" key={index} virtualIndex={index} className={slideClassName}>
              {child}
            </SwiperSlide>
          ))}
        </Swiper>
        <SliderNavigations>
          <SlideNavigationButton
            ref={previousSlideButtonRef}
            isVisible={isHovered}
            iconPosition="left"
          />
          <SlideNavigationButton
            ref={nextSlideButtonRef}
            isVisible={isHovered}
            iconPosition="right"
          />
        </SliderNavigations>
      </div>
    </div>
  );
};
