'use client';

import { useTranslations } from 'next-intl';

import ChevronRightSVG from '@/icons/ChevronRightSVG';

import { useHomeGenres } from '@/views/HomeView/HomeGenres/useHomeGenres';

import { ForwardedLink } from '@/components/ForwardedLink';

import { HomeContentBaseSlider } from '@/views/HomeView/HomeContentBaseSlider';
import { HomeGenreCard } from '@/views/HomeView/HomeGenres/HomeGenreCard';
import { HomeGenreCardSkelton } from '@/views/HomeView/HomeGenres/HomeGenreCardSkelton';
import { HomeGenreListViewAllButton } from '@/views/HomeView/HomeGenres/HomeGenreListViewAllButton';
import styles from '@/views/HomeView/HomeGenres/HomeGenres.module.scss';

export const HomeGenres = () => {
  const t = useTranslations('page.home');

  const { genres, isLoading } = useHomeGenres();

  return (
    <HomeContentBaseSlider
      xs={2}
      sm={2}
      md={4}
      lg={4}
      xl={5}
      xxl={6}
      heading={
        <ForwardedLink href={'/ganre-category'} className={styles.sectionHeading}>
          {t('genres')}
          <ChevronRightSVG className={styles.icon} />
        </ForwardedLink>
      }
      className={styles.homeGenresSlider}
      slideClassName={styles.homeGenreSlide}
    >
      {isLoading
        ? Array(10)
            .fill(null)
            .map((_, index) => <HomeGenreCardSkelton key={index} />)
        : genres.map(genre => (
            <HomeGenreCard key={genre.id} variant="homeGenreCard" genre={genre} />
          ))}
      <HomeGenreListViewAllButton href="/ganre-category" />
    </HomeContentBaseSlider>
  );
};
