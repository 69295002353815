import clsx from 'clsx';

import { ViewAllListButton, type ViewAllListButtonProps } from '@/components/ViewAllListButton';

import styles from '@/views/HomeView/HomeGenres/HomeGenreCard.module.scss';

export const HomeGenreListViewAllButton = (props: ViewAllListButtonProps) => {
  return (
    <div className={clsx(styles.homeGenreCard, styles.viewAllButton)}>
      <div className={styles.genreIcon}>
        <div style={{ paddingBottom: '100%', width: '100%' }} />
      </div>
      <p className={styles.genreTitle}></p>
      <ViewAllListButton {...props} />
    </div>
  );
};
