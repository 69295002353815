'use client';

import clsx from 'clsx';

import { breakpoints } from '@/app.config';

import type { WebGenres } from '@/api/types/home.types';

import { ForwardedLink } from '@/components/ForwardedLink';
import { Image } from '@/components/Image';

import styles from '@/views/HomeView/HomeGenres/HomeGenreCard.module.scss';

import PlaceholderImg from '../../../../public/assets/images/genre-placeholder.png';

// =================================================================

interface HomeGenreCardProps {
  genre: WebGenres;
  variant: 'homeGenreCard' | 'catalogueGenreCard';
  onClick?: () => void;
}

// =================================================================

export const HomeGenreCard = (props: HomeGenreCardProps) => {
  const { genre, variant, onClick } = props;

  const { image_web, id, title } = genre;

  return (
    <ForwardedLink
      href={`/genre/${id}`}
      onClick={onClick}
      className={clsx(styles.homeGenreCard, styles[variant])}
    >
      <div className={styles.genreIcon}>
        <Image
          aspectRatio={1 / 1}
          src={image_web || PlaceholderImg}
          alt={title}
          fill
          sizes={`62.5vw, ${breakpoints.small} 50vw`}
        />
      </div>

      <p className={styles.genreTitle}>{title}</p>
    </ForwardedLink>
  );
};
