'use client';

import ArrowNarrowRightSVG from '@/icons/ArrowNarrowRightSVG';

import { ForwardedLink } from '@/components/ForwardedLink';
import styles from '@/components/ViewAllListButton/ViewAllListButton.module.scss';

// =================================================================

export interface ViewAllListButtonProps {
  href: string;
}

// =================================================================

export const ViewAllListButton = (props: ViewAllListButtonProps) => {
  const { href } = props;

  return (
    <ForwardedLink href={href} className={styles.viewAllBtn}>
      <span className={styles.icon}>
        <ArrowNarrowRightSVG />
      </span>
      <p className={styles.text}>Смотреть все</p>
    </ForwardedLink>
  );
};
