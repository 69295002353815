'use client';

import { Fragment } from 'react';
import { Container } from 'react-bootstrap';

import { useHomeCollections } from '@/views/HomeView/HomeCollections/useHomeCollections';

import { HomeApi } from '@/api/domains/home.api';

import { LoaderButton } from '@/components/LoaderButton';

import { HomeCollectionsRow } from '@/views/HomeView/HomeCollections/HomeCollectionsRow';
import { HomeCollectionsRowSkelton } from '@/views/HomeView/HomeCollections/HomeCollectionsRowSkelton';

export const HomeCollections = () => {
  const { isLoading, handleButtonClick, hasNextPage, ref, data } = useHomeCollections();

  if (!data) {
    return <HomeCollectionsRowSkelton />;
  }

  return (
    <Fragment>
      {data.list.map(collection => (
        <HomeCollectionsRow
          key={collection.id}
          id={collection.id}
          title={collection.title}
          movies={HomeApi.extractCollectionMovies(collection.movies)}
        />
      ))}
      <Container className="mb-5">
        {hasNextPage && (
          <LoaderButton ref={ref} isLoading={isLoading} onClick={handleButtonClick} />
        )}
      </Container>
    </Fragment>
  );
};
