import { useQuery } from '@tanstack/react-query';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { OnlineTvApi } from '@/api/domains/online-tv.api';

export const useHomeTvList = () => {
  const { isInitialCheckDone } = useVerification();

  const {
    data: channels = [],
    refetch,
    status,
  } = useQuery({
    queryKey: ['home-tv-list'],
    queryFn: OnlineTvApi.fetchHomeTvList,
    staleTime: Infinity,
    enabled: isInitialCheckDone,
    select: data => data.slice(0, 12),
  });

  return { channels, refetch, status };
};
