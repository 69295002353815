import clsx from 'clsx';

import { ViewAllListButton, type ViewAllListButtonProps } from '@/components/ViewAllListButton';

import styles from '@/views/HomeView/OnlineTvList/OnlineTvCard.module.scss';

export const OnlineTVListViewAllButton = (props: ViewAllListButtonProps) => {
  return (
    <div className={clsx(styles.onlineTvCard, styles.viewAllButton)}>
      <div className={styles.skeltonCard}></div>
      <ViewAllListButton {...props} />
    </div>
  );
};
