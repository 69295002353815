import { useLocale } from 'next-intl';

import { Locale } from '@/types/common.types';

import { useInfiniteLoader } from '@/hooks/useInfiniteLoader';

import { HomeApi } from '@/api/domains/home.api';
import type { HomeCollectionList } from '@/api/types/home.types';

import { HomeQueryKeys } from '@/contexts/QueryContext/query.keys';

export const useHomeCollections = () => {
  const locale = useLocale() as Locale;

  const result = useInfiniteLoader<HomeCollectionList, unknown>({
    queryKey: HomeQueryKeys.collections(locale),
    queryFn: ({ pageParam = 1 }) => HomeApi.collections({ page: pageParam, limit: 6 }),
    initialPageParam: 1,
  });

  return result;
};
