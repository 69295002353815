'use client';

import { Container } from 'react-bootstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { useTranslations } from 'next-intl';

import ChevronRightSVG from '@/icons/ChevronRightSVG';

import { useUnfinishedMovies } from '@/views/HomeView/UnfinishedMovieList/useUnfinishedMovies';

import { ForwardedLink } from '@/components/ForwardedLink';
import { Slider } from '@/components/Slider';
import { UnfinishedMovieCard, UnfinishedMovieCardSkelton } from '@/components/UnfinishedMovieCard';

import styles from '@/views/HomeView/UnfinishedMovieList/UnfinishedMovieList.module.scss';

export const UnfinishedMovieList = () => {
  const t = useTranslations('page.home');

  const { movies, isEnable, status } = useUnfinishedMovies();

  if (status === 'pending' && isEnable) {
    return (
      <Container className="mb-4 mb-lg-5 p-0">
        <Slider
          xs={2}
          sm={2}
          md={2}
          lg={3}
          xl={4}
          xxl={4}
          heading={
            <SkeletonTheme baseColor="#0F0F12" highlightColor="#47474A" width={240}>
              <Skeleton />
            </SkeletonTheme>
          }
        >
          {Array(10)
            .fill(null)
            .map((_, index) => (
              <UnfinishedMovieCardSkelton key={index} />
            ))}
        </Slider>
      </Container>
    );
  }

  if (movies.length === 0 || !isEnable) {
    return null;
  }

  return (
    <Container className="mb-5 p-0">
      <Slider
        xs={2}
        sm={2}
        md={2}
        lg={3}
        xl={4}
        xxl={4}
        heading={
          <ForwardedLink href={'/users-history/history'} className={styles.sectionHeading}>
            {t('continueWatch')}
            <ChevronRightSVG className={styles.icon} />
          </ForwardedLink>
        }
      >
        {movies.map(movie => (
          <UnfinishedMovieCard key={movie.id} movie={movie} deepRemove={false} />
        ))}
      </Slider>
    </Container>
  );
};
