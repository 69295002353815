import { Container } from 'react-bootstrap';

import { Slider, type SliderProps } from '@/components/Slider';

export const HomeContentBaseSlider = (props: Partial<SliderProps>) => {
  const { children, heading, xs = 2, sm = 2, md = 3, lg = 4, xl = 5, xxl = 6, ...rest } = props;
  return (
    <Container className="mb-4 mb-lg-5 p-0">
      <Slider xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl} heading={heading} {...rest}>
        {children}
      </Slider>
    </Container>
  );
};
