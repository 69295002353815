import clsx from 'clsx';

import { Card, CardBody } from '@/components/Card';
import styles from '@/components/MovieCard/MovieCardSkelton.module.scss';
import { ViewAllListButton, type ViewAllListButtonProps } from '@/components/ViewAllListButton';

// =================================================================

const CARD_ASPECT_RATIO = 181 / 272;

// =================================================================

export const MovieListViewAllButton = (props: ViewAllListButtonProps) => {
  return (
    <Card className={clsx(styles.movieCard, styles.viewAllButton)}>
      <div
        style={{ paddingBottom: `${100 / CARD_ASPECT_RATIO}%` }}
        className={styles.skeltonImage}
      />
      <CardBody className={styles.movieCardBody}>
        <p className={styles.text}></p>
      </CardBody>
      <ViewAllListButton {...props} />
    </Card>
  );
};
