const ImdbSVG = () => {
  return (
    <svg width="48" height="24" viewBox="0 0 48 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6054_2707)">
        <path
          d="M48 2.06273C47.8698 1.00611 47.0792 0.163958 46.0725 0C41.6598 0 6.35937 0 1.94671 0C0.843965 0.179692 0 1.17255 0 2.36911C0 4.29272 0 19.6783 0 21.6011C0 22.926 1.03263 24 2.30734 24C6.64737 24 41.3718 24 45.7119 24C46.8856 24 47.8548 23.0883 48 21.9083C48 17.9393 48 4.04679 48 2.06273Z"
          fill="#F6C700"
        />
        <path d="M5.78931 4.82263H9.59842V19.3677H5.78931V4.82263Z" fill="black" />
        <path
          d="M16.7958 11.5226C16.4685 9.30583 16.2866 8.07365 16.2507 7.82772C16.0912 6.64109 15.9393 5.60849 15.794 4.72742C15.4643 4.72742 13.8189 4.72742 10.8555 4.72742V19.2725H14.1921L14.2038 9.66852L15.6079 19.2725H17.9845L19.316 9.45488L19.3285 19.2725H22.6543V4.72742H17.6781L16.7958 11.5226Z"
          fill="black"
        />
        <path
          d="M28.9427 7.75319C28.9845 7.93868 29.0062 8.35934 29.0062 9.01683C29.0062 9.58075 29.0062 14.0921 29.0062 14.656C29.0062 15.624 28.9427 16.2169 28.8167 16.4355C28.6898 16.6541 28.3526 16.7626 27.8058 16.7626C27.8058 15.8078 27.8058 8.16971 27.8058 7.21494C28.2207 7.21494 28.5037 7.25883 28.6539 7.34495C28.8042 7.4319 28.901 7.5677 28.9427 7.75319ZM30.6632 19.1243C31.1165 19.0257 31.4972 18.8518 31.8061 18.6042C32.1141 18.3558 32.3303 18.0122 32.4538 17.5725C32.5782 17.1336 32.6517 16.2616 32.6517 14.9574C32.6517 14.4473 32.6517 10.3608 32.6517 9.84987C32.6517 8.47361 32.5974 7.55114 32.5131 7.08245C32.428 6.61294 32.2168 6.18648 31.8787 5.80391C31.5398 5.42134 31.0456 5.14642 30.3961 4.97915C29.7458 4.81188 28.6856 4.72742 26.8416 4.72742C26.6521 4.72742 25.7055 4.72742 24 4.72742V19.2725H28.6147C29.6782 19.2394 30.361 19.1905 30.6632 19.1243Z"
          fill="black"
        />
        <path
          d="M38.7976 16.9506C38.7275 17.1352 38.4203 17.2288 38.1882 17.2288C37.9612 17.2288 37.8101 17.1394 37.7333 16.9597C37.6565 16.7808 37.6189 16.3726 37.6189 15.7333C37.6189 15.3491 37.6189 12.2736 37.6189 11.8894C37.6189 11.2269 37.6523 10.8137 37.7199 10.6489C37.7867 10.4858 37.9336 10.403 38.1607 10.403C38.3928 10.403 38.7041 10.4966 38.7843 10.6846C38.8636 10.8725 38.9037 11.2741 38.9037 11.8894C38.9037 12.1378 38.9037 13.3799 38.9037 15.6165C38.8794 16.3825 38.8444 16.8272 38.7976 16.9506ZM33.9492 19.1458H37.3785C37.5213 18.5902 37.5997 18.2813 37.6156 18.2192C37.9261 18.5918 38.2675 18.8717 38.6424 19.0572C39.0155 19.2435 39.574 19.3362 40.0064 19.3362C40.6083 19.3362 41.1275 19.1797 41.5658 18.8659C42.0032 18.5529 42.282 18.1819 42.4006 17.7555C42.5191 17.3282 42.5784 16.679 42.5784 15.8062C42.5784 15.3979 42.5784 12.1337 42.5784 11.7254C42.5784 10.8477 42.5583 10.2747 42.5191 10.0055C42.4799 9.73641 42.363 9.46232 42.1685 9.1816C41.974 8.90088 41.691 8.6831 41.3204 8.52742C40.9497 8.37175 40.5123 8.29391 40.0081 8.29391C39.5698 8.29391 39.0088 8.38085 38.6349 8.55227C38.2617 8.72368 37.9228 8.98369 37.6189 9.33231C37.6189 9.01681 37.6189 7.44017 37.6189 4.60071H33.9492V19.1458Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_6054_2707">
          <rect width="48" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ImdbSVG;
