const KinopoiskSVG = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6054_2732)">
        <path
          d="M23.8903 13.6298L12.6207 12.8726L22.9115 17.001C23.3939 15.9501 23.729 14.8176 23.8903 13.6298Z"
          fill="url(#paint0_radial_6054_2732)"
        />
        <path
          d="M21.4605 19.3832C19.2643 22.1934 15.8431 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C15.8529 0 19.2817 1.81582 21.4773 4.63836L11.2864 10.0932L18.2212 2.97034H14.3771L9.65588 9.52358V2.97034H6.53418V21.0297H9.65588V14.4871L14.3771 21.0297H18.2212L11.4665 14.1307L21.4605 19.3832Z"
          fill="url(#paint1_radial_6054_2732)"
        />
        <path
          d="M22.9115 6.99899C23.3937 8.04945 23.7287 9.18154 23.8901 10.3688L12.6966 11.0966L22.9115 6.99899Z"
          fill="url(#paint2_radial_6054_2732)"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_6054_2732"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(45.1313) scale(33.8637 33.8633)"
        >
          <stop offset="0.5" stopColor="#FF5500" />
          <stop offset="1" stopColor="#BBFF00" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_6054_2732"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(45.1313) scale(33.8637 33.8633)"
        >
          <stop offset="0.5" stopColor="#FF5500" />
          <stop offset="1" stopColor="#BBFF00" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_6054_2732"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(45.1313) scale(33.8637 33.8633)"
        >
          <stop offset="0.5" stopColor="#FF5500" />
          <stop offset="1" stopColor="#BBFF00" />
        </radialGradient>
        <clipPath id="clip0_6054_2732">
          <rect width="24" height="24" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KinopoiskSVG;
