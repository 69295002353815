import { Fragment } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { MovieCardSkelton } from '@/components/MovieCard/MovieCardSkelton';

import styles from '@/views/HomeView/HomeCollections/HomeCollectionsRow.module.scss';
import { HomeContentBaseSlider } from '@/views/HomeView/HomeContentBaseSlider';

export const HomeCollectionsRowSkelton = () => {
  return (
    <Fragment>
      {Array(3)
        .fill(null)
        .map((_, index) => (
          <HomeContentBaseSlider
            key={index}
            heading={
              <SkeletonTheme baseColor="#0F0F12" highlightColor="#47474A" width={240}>
                <Skeleton />
              </SkeletonTheme>
            }
            className={styles.homeCollectionsSlider}
            slideClassName={styles.collectionsSlide}
          >
            {Array(10)
              .fill(null)
              .map((_, j) => (
                <MovieCardSkelton key={j} />
              ))}
          </HomeContentBaseSlider>
        ))}
    </Fragment>
  );
};
